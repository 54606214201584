import React, { Component } from 'react';
import TopMenu from '../../shared/components/top-menu';
import AppContext from '../../core/context/app.context';
import UtilityReviewResolved from './components/resolved';
import UtilityReviewUnresolved from './components/unresolved';
import { WorkOrdersAssignmentsModal } from '../../shared/components/modal/assignment';
import Loader from '../../shared/components/loader';
import { addPhoto } from '../../core/services/photo.service';
import * as commentService from '../../core/services/comment.service';
import { getWorkOrderDetails } from '../../core/services/workOrder.service';
import { convertUrlsToSignedUrls } from '../../core/services/workOrder.service';
import { LIST_WORORDERS_FOR_UTILITY_REVIEW } from '../../graphql/queries/workorder';
import { UPDATE_WORKORDER_MUTATION } from '../../graphql/mutations/workorder';
import { useTimeZone } from '../../helpers/date';
import { orderPhotosByType } from '../../helpers/workOrderPhoto';
import withApollo from '../../hoc/withApollo';
import ProjectRouteWrapper from '../../hoc/projectRouteWrapper';
import { stripSpecialCharacters } from '../quality-assurance/components/modal/addPhotoModal';


class UtilityReview extends Component {

    _isMounted = false;
    static contextType = AppContext;
  
    replaceFileInputRef = React.createRef();
    addFileInputRef = React.createRef();
  
    state = {
      resolvedActive: false,
      unresolvedActive: true,
      resolvedWorkOrders: [],
      unresolvedWorkOrders: [],
      workOrdersVerified: [],
      error: false,
      loading: true,
      searchBy: '',
      resolvedLoading: true,
      unresolvedLoading: true,
      photoType: null,
      showAddModal: false,
      uploadingNewPhoto: false,
      showCustomInputText: false,
      customPhotoType: '',
    };
  
    setState(object) {
      if (this._isMounted) {
        super.setState(object);
      }
    }
  
    async componentWillMount() {
      this._isMounted = true;
      await this.getWorkOrdersForUtilityReview();
      this.setState({ loading: false });
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }
  
    getWorkOrdersForUtilityReview = async () => {
      try {
        this.setState({ loading: true });
        const { currentProject } = this.context;
        let workOrders = [];
        if (currentProject) {
          const { client } = this.props;
          const { projectTimeZone = null, projectWorkOrderType: workOrderType } = currentProject;
          let offset = null;
          do {
            let { data } = await client.query({
              query: LIST_WORORDERS_FOR_UTILITY_REVIEW,
              variables: {
                projectId: currentProject.id,
                offset,
                limit: 1000,
                workOrderType
              },
              fetchPolicy: 'no-cache'
            });
            data = data.listWorkOrdersForUtilityReview.items;
            if (data.length > 0) {
              workOrders = [...workOrders, ...data];
              offset = data.length + (offset || 0);
            } else {
              offset = null;
            }
          } while (offset !== null);
          const workOrdersWithTimezone = useTimeZone(workOrders, ['workOrderLastVisitDate'], projectTimeZone);
          // const workOrdersWithTimezone = useTimeZone(things, ['workOrderLastVisitDate'], projectTimeZone);
          const unresolvedWorkOrders = workOrdersWithTimezone.filter(wo => wo.workOrderReasonCodes.includes('RTU Review'));
          const resolvedWorkOrders = workOrdersWithTimezone.filter(wo => wo.workOrderReasonCodes.includes('RTU Resolved'));
          this.setState({ unresolvedWorkOrders, resolvedWorkOrders, resolvedLoading: false, unresolvedLoading: false, loading: false });
        }
      } catch (error) {
        console.error(error);
        this.setState({ error: true });
        this.setState({ loading: false });
      }
    }

    onResolve = async (workOrder, comment) => {
      try {
        this.setState({ loading: true });
        const { client } = this.props;
        const id = workOrder.id;
        const workOrderReasonCodes = [ ...workOrder.workOrderReasonCodes ];
        const rtuIndex = workOrderReasonCodes.indexOf('RTU Review');
        workOrderReasonCodes[rtuIndex] = 'RTU Resolved';
        await client.query({
          query: UPDATE_WORKORDER_MUTATION,
          variables: {
            id,
            workOrderReasonCodes
          },
          fetchPolicy: 'no-cache'
        });
        await this.addComment(id, comment);
        await this.getWorkOrdersForUtilityReview();
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.setState({ loading: false });
      }
    }
  
    formatWorkOrderDetailsPhotos = async (workOrder) => {
      let workOrderCopy = Object.assign({}, workOrder);
      if (workOrderCopy.photos && workOrderCopy.photos.items && workOrderCopy.photos.items.length > 0) {
        workOrderCopy = orderPhotosByType(workOrderCopy);
        const urls = workOrderCopy.photos.items.map(({ file }) => {
          return `${file.bucket}/${file.key}`;
        });
        let signedUrls = await convertUrlsToSignedUrls(JSON.stringify(urls));
        signedUrls = signedUrls.map((url, index) => {
          return { original: url, thumbnail: url, photoName: workOrder.photos.items[index].file.key, bucket: workOrder.photos.items[index].file.bucket, photoDate: workOrder.photos.items[index].photoDate }
        });
        workOrderCopy.photos = signedUrls;
      } else {
        workOrderCopy.photos = [];
      }
      return workOrderCopy;
    }
  
    addComment = async (workOrderId, comment) => {
      const { user } = this.context;
      const userId = user.id;
      await commentService.addComment(userId, workOrderId, comment);
    }
  
    showResolved = () => {
      const { resolvedLoading } = this.state;
      if (!resolvedLoading) {
        this.setState({ resolvedActive: true, unresolvedActive: false, searchBy: '' });
      }
    }
  
    showUnresolved = () => {
      const { unresolvedLoading } = this.state;
      if (!unresolvedLoading) {
        this.setState({ resolvedActive: false, unresolvedActive: true, searchBy: '' });
      }
    }
  
    onSearch = (searchBy) => {
      this.setState({ searchBy });
    }
  
    onPhotoTypeChange = (event, data) => {
      this.setState({ photoType: data.value, showCustomInputText: data.value === 'Custom' });
    }
  
    onCustomTypeChange = (event, data) => {
      this.setState({ customPhotoType: data.value });
    }
  
    showAddPhotoModal = () => {
      const { currentProject } = this.context;
      if (currentProject) {
        this.setState({ showAddModal: true });
      }
    }
  
    closeAddPhotoModal = () => {
      this.setState({ showAddModal: false, photoType: null, customPhotoType: '', showCustomInputText: false });
    }
  
    addPhoto = async (event, workOrderId, oldMeterNumber) => {
      this.setState({ uploadingNewPhoto: true });
      const { currentProject } = this.context;
      const { photoType, customPhotoType } = this.state;
      const sanitizedCustomPhotoType = stripSpecialCharacters(customPhotoType);
      const type = customPhotoType ? sanitizedCustomPhotoType : stripSpecialCharacters(photoType);
      const photo = event.target.files[0];
      const sanitizedPhotoName = stripSpecialCharacters(photo.name);
      if (photo) {
        await addPhoto(photo, workOrderId, oldMeterNumber, type, currentProject.id, sanitizedPhotoName);
        this.closeAddPhotoModal();
        this.setState({ uploadingNewPhoto: false });
      }
    }

    handleRefresh = async () => {
      try {
        const { selectedWorkOrder } = this.context;
        await this.getWorkOrderDetails(selectedWorkOrder.id);
        await this.getWorkOrdersForUtilityReview();
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    getWorkOrderDetails = async (id) => {
      try {
        this.setState({ loading: true });
        const { currentProject, setSelectedWorkOrder } = this.context;
        this.setState({ modalDetailsLoading: true });
        let workOrder = await getWorkOrderDetails(id);
        workOrder = await this.formatWorkOrderDetailsPhotos(workOrder);
        workOrder = useTimeZone(workOrder, ['workOrderLastVisitDate', 'workOrderAuditDate'], currentProject.projectTimeZone);
        if (workOrder.photos && workOrder.photos.length > 0) {
          workOrder.photos = useTimeZone(workOrder.photos, ['photoDate'], currentProject.projectTimeZone);
        }
        if (workOrder.workOrderComments && workOrder.workOrderComments.items && workOrder.workOrderComments.items.length > 0) {
          workOrder.workOrderComments.items = useTimeZone(workOrder.workOrderComments.items, ['contentDate'], currentProject.projectTimeZone);
        }
        if (workOrder.workOrderHistorys && workOrder.workOrderHistorys.items && workOrder.workOrderHistorys.items.length > 0) {
          workOrder.workOrderHistorys.items = useTimeZone(workOrder.workOrderHistorys.items, ['historyDate'], currentProject.projectTimeZone);
        }
        setSelectedWorkOrder(workOrder);
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({ loading: false });
      }
    }
  
    renderTopMenu = () => {
      const { currentProject } = this.context;
      const {
        resolvedWorkOrders,
        unresolvedWorkOrders,
        resolvedLoading,
        unresolvedLoading
      } = this.state;
      const topMenuConfig = {
        header: {
          title: `${currentProject && currentProject.projectName ? `${currentProject.projectName} - Meters` : 'Meters'}`,
          iconName: 'flag'
        },
        tabs: [
          {
            active: this.state.unresolvedActive,
            onClick: this.showUnresolved,
            title: 'Utility Review',
            label: {
              color: 'blue',
              value: unresolvedWorkOrders.length
            },
            loader: {
              loading: unresolvedLoading
            }
          },
          {
            active: this.state.resolvedActive,
            onClick: this.showResolved,
            title: 'Utility Resolved',
            label: {
              color: 'green',
              value: resolvedWorkOrders ? resolvedWorkOrders.length : 0
            },
            loader: {
              loading: resolvedLoading
            }
          },
          {
            onClick: this.getWorkOrdersForUtilityReview,
            refresh: true
          }
        ]
      };
      return <TopMenu config={topMenuConfig} style={{ zIndex: 2 }} />;
    }
  
    render() {
      const {
        resolvedWorkOrders,
        unresolvedWorkOrders,
        resolvedActive,
        unresolvedActive,
        photoType,
        showAddModal,
        uploadingNewPhoto,
        showCustomInputText,
        customPhotoType,
        loading,
      } = this.state;
      const { user, modals: { assignmentModal }} = this.context;
      return (
        <ProjectRouteWrapper>
          {this.renderTopMenu()}
          <Loader loading={loading} />
          { assignmentModal && <WorkOrdersAssignmentsModal handleRefresh={this.handleRefresh} /> }
          {unresolvedActive && <UtilityReviewUnresolved
            user={user}
            qualityAssuranceHeaderLabelColor='blue'
            addComment={this.addComment}
            workOrders={unresolvedWorkOrders}
            onSearch={this.onSearch}
            formatWorkOrderDetailsPhotos={this.formatWorkOrderDetailsPhotos}
            customPhotoType={customPhotoType}
            onCustomTypeChange={this.onCustomTypeChange}
            showCustomInputText={showCustomInputText}
            addFileInputRef={this.addFileInputRef}
            showAddModal={showAddModal}
            uploadingNewPhoto={uploadingNewPhoto}
            photoType={photoType}
            showAddPhotoModal={this.showAddPhotoModal}
            addPhoto={this.addPhoto}
            onPhotoTypeChange={this.onPhotoTypeChange}
            closeAddPhotoModal={this.closeAddPhotoModal}
            onResolve={this.onResolve}
            getWorkOrderDetails={this.getWorkOrderDetails}
          />}
          {resolvedActive && <UtilityReviewResolved
            user={user}
            qualityAssuranceHeaderLabelColor='green'
            addComment={this.addComment}
            workOrders={resolvedWorkOrders}
            onSearch={this.onSearch}
            formatWorkOrderDetailsPhotos={this.formatWorkOrderDetailsPhotos}
            customPhotoType={customPhotoType}
            onCustomTypeChange={this.onCustomTypeChange}
            showCustomInputText={showCustomInputText}
            addFileInputRef={this.addFileInputRef}
            showAddModal={showAddModal}
            uploadingNewPhoto={uploadingNewPhoto}
            photoType={photoType}
            showAddPhotoModal={this.showAddPhotoModal}
            addPhoto={this.addPhoto}
            onPhotoTypeChange={this.onPhotoTypeChange}
            closeAddPhotoModal={this.closeAddPhotoModal}
            getWorkOrderDetails={this.getWorkOrderDetails}
          />}
        </ProjectRouteWrapper>
      );
    }
  }
  
export default withApollo(UtilityReview);