import {
  SET_LAYOUT_TYPE,
  SET_MAP,
  SET_LAYOUT_DATA,
  SET_GEOJSON_SIZE,
  SET_GEOJSON_FILE_URL,
  ADD_SELECTED_WORKORDERS,
  DELETE_SELECTED_WORKORDERS,
  SET_SHOW_ASSIGNEMNTS_MODAL,
  SET_SHOW_GROUP_MODAL,
  RESET_MAP_STATE,
  SET_SHOW_MAP_TOOLBAR,
  SET_SELECTED_WORKORDERS,
  RESIZE_MAP,
  SET_POLYGONS,
  SET_POPUP,
  SET_LAYOUT_ICON_POPUP,
  SET_STYLE,
  SET_OVERLAY_GEOJSON_FILE_URL,
  SET_LOADING,
  SET_PREVIOUS_MAP,
  SET_SEARCH_BY,
  SET_FILTER_BY,
  SET_GEOJSON_DATA_COPY,
  SET_MAP_STATUS_ID,
  SET_HAS_CACHED_VERSION,
  SET_MOUNTED,
  SET_MAP_REF,
  SET_CUSTOM_DATA_URL,
  SET_DISABLE_VIEWS,
  SET_DISABLE_FILTERS,
  SET_CUSTOM_VIEW_DATA
} from '../types/map.types';
import {
  MAP_LAYOUT_TYPES,
  MAP_LAYOUT_IDS,
} from '../../../constants/mapLayout';
import mapboxgl from 'mapbox-gl';
import config from '../../../config';
import { fitBounds } from '../../../helpers/map';

const initialState = {
  layoutType: MAP_LAYOUT_TYPES.WorkOrderStatus,
  map: null,
  layoutData: {},
  geoJsonSize: 0,
  geoJsonFileUrl: '',
  overlayGeoJsonFileUrl: '',
  customDataUrl: '',
  geoJsonBbox: [],
  selectedWorkOrders: [],
  showAssignmentsModal: false,
  showGroupModal: false,
  showMapToolbar: false,
  polygons: {},
  popup: null,
  layoutIconPopup: null,
  style: config.mapbox.style,
  loading: false,
  loadingMessage: '',
  previousMap: null,
  searchBy: '',
  filterBy: [],
  geoJsonDataCopy: null,
  mapStatusId: '',
  hasCachedVersion: false,
  mounted: false,
  disableFilters: false,
  disableViews: false,
  customViewData: []
}

const getSelectedWorkOrders = (polygons) => {
  const selectedWorkOrders = [];
  Object.values(polygons).forEach(polygon => {
    polygon.forEach(workOrder => {
      if (selectedWorkOrders.findIndex(i => i.id === workOrder.id) === -1) {
        selectedWorkOrders.push(workOrder);
      }
    })
  });
  return selectedWorkOrders;
}

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_REF:
      return { ...state, map: action.payload };
    case SET_MOUNTED:
      return { ...state, mounted: action.payload };
    case SET_CUSTOM_VIEW_DATA:
      return { ...state, customViewData: [...action.payload] };
    case SET_DISABLE_FILTERS:
      return { ...state, disableFilters: action.payload };
    case SET_DISABLE_VIEWS:
      return { ...state, disableViews: action.payload };
    case SET_HAS_CACHED_VERSION:
      return { ...state, hasCachedVersion: action.payload };
    case SET_MAP_STATUS_ID:
      return { ...state, mapStatusId: action.payload };
    case SET_PREVIOUS_MAP:
      return { ...state, previousMap: action.payload };
    case SET_SEARCH_BY:
      return { ...state, searchBy: action.payload };
    case SET_FILTER_BY:
      return { ...state, filterBy: action.payload };
    case SET_STYLE:
      return { ...state, style: action.payload };
    case SET_POPUP:
      return { ...state, popup: action.payload };
    case SET_LAYOUT_ICON_POPUP:
      return { ...state, layoutIconPopup: action.payload };
    case SET_POLYGONS:
      return { ...state, polygons: action.payload };
    case RESIZE_MAP:
      state.map.resize();
      return { ...state };
    case RESET_MAP_STATE:
      return { ...initialState };
    case SET_SELECTED_WORKORDERS:
      return { ...state, selectedWorkOrders: action.payload };
    case SET_SHOW_MAP_TOOLBAR:
      return { ...state, showMapToolbar: action.payload };
    case SET_SHOW_ASSIGNEMNTS_MODAL:
      return { ...state, showAssignmentsModal: action.payload }
    case SET_SHOW_GROUP_MODAL:
      return { ...state, showGroupModal: action.payload }
    case ADD_SELECTED_WORKORDERS: {
      const polygon = action.payload;
      const polygons = state.polygons;
      polygons[polygon.id] = polygon.workOrders;
      return { ...state, polygons: { ...polygons }, selectedWorkOrders: [...getSelectedWorkOrders(polygons)] };
    }
    case DELETE_SELECTED_WORKORDERS: {
      const polygonId = action.payload;
      delete state.polygons[polygonId];
      return { ...state, polygons: { ...state.polygons }, selectedWorkOrders: [...getSelectedWorkOrders(state.polygons)] };
    }
    case SET_GEOJSON_SIZE:
      return { ...state, geoJsonSize: action.payload };
    case SET_GEOJSON_FILE_URL:
      return { ...state, geoJsonFileUrl: action.payload };
    case SET_OVERLAY_GEOJSON_FILE_URL:
      return { ...state, overlayGeoJsonFileUrl: action.payload };
    case SET_CUSTOM_DATA_URL:
      return { ...state, customDataUrl: action.payload };
    case SET_LAYOUT_DATA:
      return { ...state, layoutData: action.payload };
    case SET_LOADING:
      return { ...state, loading: action.payload.loading, loadingMessage: action.payload.loadingMessage };
    case SET_GEOJSON_DATA_COPY:
      return { ...state, geoJsonDataCopy: action.payload };
    case SET_LAYOUT_TYPE:
      const { map } = state;
      if (map) {
        const layoutType = action.payload;
        map.setFilter(MAP_LAYOUT_IDS.Primary, undefined);
        map.setFilter(MAP_LAYOUT_IDS.Secondary, undefined);
        map.setLayoutProperty(MAP_LAYOUT_IDS.Tertiary, 'visibility', 'none');
        if (map.getLayer(MAP_LAYOUT_IDS.Overlay)) {
          map.setLayoutProperty(MAP_LAYOUT_IDS.Overlay, 'visibility', 'none');
        };
        if (map.getLayer(MAP_LAYOUT_IDS.MultiPolygonOverlay)) {
          map.setLayoutProperty(MAP_LAYOUT_IDS.MultiPolygonOverlay, 'visibility', 'none');
          map.setLayoutProperty(MAP_LAYOUT_IDS.MultiPolygonOverlayLabel, 'visibility', 'none');
        };

        if (layoutType !== MAP_LAYOUT_TYPES.CustomDataFields) {
          map.setLayoutProperty(MAP_LAYOUT_IDS.Primary, 'visibility', 'visible');
          map.setLayoutProperty(MAP_LAYOUT_IDS.Secondary, 'visibility', 'visible');
          map.setLayoutProperty(MAP_LAYOUT_IDS.PrimaryCluster, 'visibility', 'visible');
          map.setLayoutProperty(MAP_LAYOUT_IDS.PrimaryClusterText, 'visibility', 'visible');
          map.setLayoutProperty(MAP_LAYOUT_IDS.PrimaryUnclusteredPoint, 'visibility', 'visible');
          
          // Check if Custom layers exist before modifying them for all layout types
          if (map.getLayer(MAP_LAYOUT_IDS.Custom)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.Custom, 'visibility', 'none');
          }
          if (map.getLayer(MAP_LAYOUT_IDS.CustomCluster)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.CustomCluster, 'visibility', 'none');
          }
          if (map.getLayer(MAP_LAYOUT_IDS.CustomUnclusteredPoint)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.CustomUnclusteredPoint, 'visibility', 'none');
          }
          if (map.getLayer(MAP_LAYOUT_IDS.CustomClusterText)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.CustomClusterText, 'visibility', 'none');
          }
        }

        if (layoutType === MAP_LAYOUT_TYPES.CustomDataFields) {
          // Check if Custom layers exist before modifying them
          if (map.getLayer(MAP_LAYOUT_IDS.Custom)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.Custom, 'visibility', 'visible');
          }
          if (map.getLayer(MAP_LAYOUT_IDS.CustomCluster)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.CustomCluster, 'visibility', 'visible');
          }
          if (map.getLayer(MAP_LAYOUT_IDS.CustomUnclusteredPoint)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.CustomUnclusteredPoint, 'visibility', 'visible');
          }
          if (map.getLayer(MAP_LAYOUT_IDS.CustomClusterText)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.CustomClusterText, 'visibility', 'visible');
          }
          
          map.setFilter(MAP_LAYOUT_IDS.Primary, undefined);
          map.setFilter(MAP_LAYOUT_IDS.Secondary, undefined);
          map.setLayoutProperty(MAP_LAYOUT_IDS.Primary, 'visibility', 'none');
          map.setLayoutProperty(MAP_LAYOUT_IDS.Secondary, 'visibility', 'none');
          map.setLayoutProperty(MAP_LAYOUT_IDS.Tertiary, 'visibility', 'none');
          if (map.getLayer(MAP_LAYOUT_IDS.Overlay)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.Overlay, 'visibility', 'none');
          }
          map.setLayoutProperty(MAP_LAYOUT_IDS.PrimaryCluster, 'visibility', 'none');
          map.setLayoutProperty(MAP_LAYOUT_IDS.PrimaryClusterText, 'visibility', 'none');
          map.setLayoutProperty(MAP_LAYOUT_IDS.PrimaryUnclusteredPoint, 'visibility', 'none');
        } else if (layoutType === MAP_LAYOUT_TYPES.AppointmentTimeWindow) {
          // Handle Appointment Time view
          map.setFilter(MAP_LAYOUT_IDS.Primary, undefined);
          map.setFilter(MAP_LAYOUT_IDS.Secondary, undefined);
          map.setLayoutProperty(MAP_LAYOUT_IDS.Primary, 'visibility', 'visible');
          map.setLayoutProperty(MAP_LAYOUT_IDS.Secondary, 'visibility', 'visible');
          map.setLayoutProperty(MAP_LAYOUT_IDS.Tertiary, 'visibility', 'none');
          
          if (map.getLayer(MAP_LAYOUT_IDS.Overlay)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.Overlay, 'visibility', 'none');
          }
        } else if (layoutType === MAP_LAYOUT_TYPES.WorkOrderStatusPOI) {
          if (map.getLayer(MAP_LAYOUT_IDS.Overlay)) {
            map.moveLayer(MAP_LAYOUT_IDS.Overlay);
            map.setLayoutProperty(MAP_LAYOUT_IDS.Overlay, 'visibility', 'visible');
          };
          map.setLayoutProperty(MAP_LAYOUT_IDS.Tertiary, 'visibility', 'visible');
          map.setFilter(MAP_LAYOUT_IDS.Primary, ['case', ['==', ['get', 'technicianWorkOrderLastVisitDate'], null], true, false]);
          map.setFilter(MAP_LAYOUT_IDS.Secondary, ['case', ['==', ['get', 'technicianWorkOrderLastVisitDate'], null], true, false]);
          if (map.getLayer(MAP_LAYOUT_IDS.MultiPolygonOverlay)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.MultiPolygonOverlay, 'visibility', 'visible');
            map.moveLayer(MAP_LAYOUT_IDS.MultiPolygonOverlay);
            map.moveLayer(MAP_LAYOUT_IDS.Primary);
            map.moveLayer(MAP_LAYOUT_IDS.Secondary);
            map.moveLayer(MAP_LAYOUT_IDS.Tertiary);
            map.moveLayer(MAP_LAYOUT_IDS.Overlay);
            map.setLayoutProperty(MAP_LAYOUT_IDS.MultiPolygonOverlayLabel, 'visibility', 'visible');
            map.moveLayer(MAP_LAYOUT_IDS.MultiPolygonOverlayLabel);
          };
        } else if (layoutType === MAP_LAYOUT_TYPES.CyclePOI) {
          if (map.getLayer(MAP_LAYOUT_IDS.Overlay)) {
            map.moveLayer(MAP_LAYOUT_IDS.Overlay);
            map.setLayoutProperty(MAP_LAYOUT_IDS.Overlay, 'visibility', 'visible');
          };
          map.setLayoutProperty(MAP_LAYOUT_IDS.Tertiary, 'visibility', 'visible');
          map.setFilter(MAP_LAYOUT_IDS.Primary, ['case', ['==', ['get', 'technicianWorkOrderLastVisitDate'], null], true, false]);
          map.setFilter(MAP_LAYOUT_IDS.Secondary, ['case', ['==', ['get', 'technicianWorkOrderLastVisitDate'], null], true, false]);
          if (map.getLayer(MAP_LAYOUT_IDS.MultiPolygonOverlay)) {
            map.setLayoutProperty(MAP_LAYOUT_IDS.MultiPolygonOverlay, 'visibility', 'visible');
            map.moveLayer(MAP_LAYOUT_IDS.MultiPolygonOverlay);
            map.moveLayer(MAP_LAYOUT_IDS.Primary);
            map.moveLayer(MAP_LAYOUT_IDS.Secondary);
            map.moveLayer(MAP_LAYOUT_IDS.Tertiary);
            map.moveLayer(MAP_LAYOUT_IDS.Overlay);
            map.setLayoutProperty(MAP_LAYOUT_IDS.MultiPolygonOverlayLabel, 'visibility', 'visible');
            map.moveLayer(MAP_LAYOUT_IDS.MultiPolygonOverlayLabel);
          };
        } else if (layoutType === MAP_LAYOUT_TYPES.Assignment) {
          const filter = [
            'match',
            ['get', 'ws'],
            'Open',
            true,
            'Assigned',
            true,
            'InProgress',
            true,
            false
          ];
          map.setFilter(MAP_LAYOUT_IDS.Primary, filter);
          map.setFilter(MAP_LAYOUT_IDS.Secondary, filter);
        } else if (layoutType === MAP_LAYOUT_TYPES.WorkOrderStatus) {
          map.setLayoutProperty(MAP_LAYOUT_IDS.Tertiary, 'visibility', 'visible');
          map.setFilter(MAP_LAYOUT_IDS.Primary, ['case', ['==', ['get', 'technicianWorkOrderLastVisitDate'], null], true, false]);
          map.setFilter(MAP_LAYOUT_IDS.Secondary, ['case', ['==', ['get', 'technicianWorkOrderLastVisitDate'], null], true, false]);
        }
        return { ...state, layoutType: action.payload };
      }
      return { ...state };
    case SET_MAP:
      if (action.payload.getLayer(MAP_LAYOUT_IDS.Overlay)) {
        action.payload.setLayoutProperty(MAP_LAYOUT_IDS.Overlay, 'visibility', 'none');
      };
      if (action.payload.getLayer(MAP_LAYOUT_IDS.MultiPolygonOverlay)) {
        action.payload.setLayoutProperty(MAP_LAYOUT_IDS.MultiPolygonOverlay, 'visibility', 'none');
        action.payload.setLayoutProperty(MAP_LAYOUT_IDS.MultiPolygonOverlayLabel, 'visibility', 'none');
      };
      if (action.payload.getLayer(MAP_LAYOUT_IDS.Custom)) {
        action.payload.setLayoutProperty(MAP_LAYOUT_IDS.Custom, 'visibility', 'none');
        action.payload.setLayoutProperty(MAP_LAYOUT_IDS.CustomCluster, 'visibility', 'none');
        action.payload.setLayoutProperty(MAP_LAYOUT_IDS.CustomUnclusteredPoint, 'visibility', 'none');
        action.payload.setLayoutProperty(MAP_LAYOUT_IDS.CustomClusterText, 'visibility', 'none');
      }
      action.payload.addControl(new mapboxgl.NavigationControl({ showZoom: true, showCompass: true, visualizePitch: true }));
      action.payload.addControl(new mapboxgl.ScaleControl({maxWidth: 200,unit: 'imperial'}));
      action.payload.addControl(new mapboxgl.FullscreenControl());
      action.payload.setFilter(MAP_LAYOUT_IDS.Primary, ['case', ['==', ['get', 'technicianWorkOrderLastVisitDate'], null], true, false]);
      fitBounds(action.payload, state.geoJsonDataCopy);
      return { ...state, map: action.payload, layoutType: MAP_LAYOUT_TYPES.WorkOrderStatus };
    default:
      return state;
  }
}

export default mapReducer;