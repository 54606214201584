import React from 'react';
import { Button, Header, Image, Popup } from 'semantic-ui-react';
import WORKORDER_PERSON_POSITION_ICON from '../../../../assets/icons/pins/woPersonPosition.png';
import BLACKOUT_ICON from '../../../../assets/icons/pins/wo-assigned-black-out.png';
import OPEN_ICON from '../../../../assets/icons/pins/wo-open.png';
import ASSIGNED_ICON from '../../../../assets/icons/pins/wo-assigned-person.png';
import APPOINTMENT_ICON from '../../../../assets/icons/pins/wo-appointment.png';
import SITE_TEST_ICON from '../../../../assets/icons/pins/wo-sitetest.png';
import CHECKED_ICON from '../../../../assets/icons/pins/wo-checked.png';
import SAVED_ICON from '../../../../assets/icons/pins/wo-saved-in-review.png';
import THREE_STRIKE_ICON from '../../../../assets/icons/pins/wo-3-strike.png';
import ASSIGNED_CNC_ICON from '../../../../assets/icons/pins/wo-assigned-cnc.png';
import { connect } from 'react-redux';
import { MAP_LAYOUT_TYPES } from '../../../../constants/mapLayout';
import './styles.css';

const LegendItem = ({ image, text }) => (
  <div className='legend-item'>
    <div className='legend-image'>
      <Image src={image} />
    </div>
    <div className='legend-image-description '>
      {text}
    </div>
  </div>
)

const ColorLegendItem = ({ color, text }) => (
  <div className='legend-item'>
    <div className='legend-image' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ 
        backgroundColor: color, 
        width: '20px', 
        height: '20px', 
        borderRadius: '50%',
        border: '1px solid #ccc'
      }} />
    </div>
    <div className='legend-image-description '>
      {text}
    </div>
  </div>
)

const Legend = ({ mapStatusId, layoutType }) => (
  <>
    {!mapStatusId && (layoutType === MAP_LAYOUT_TYPES.WorkOrderStatus || layoutType === MAP_LAYOUT_TYPES.AppointmentTimeWindow) ? (
      <div className='legend'>
        <Popup
          content={(
            <div>
              <Header as='h3' className='legend-title'>Map Legend</Header>
              
              {layoutType === MAP_LAYOUT_TYPES.WorkOrderStatus && (
                <>
                  <LegendItem image={WORKORDER_PERSON_POSITION_ICON} text='Technician' />
                  <LegendItem image={BLACKOUT_ICON} text='In Blackout' />
                  <LegendItem image={OPEN_ICON} text='Open' />
                  <LegendItem image={ASSIGNED_ICON} text='Assigned' />
                  <LegendItem image={ASSIGNED_CNC_ICON} text='In Progress' />
                  <LegendItem image={APPOINTMENT_ICON} text='Appointment' />
                  <LegendItem image={SITE_TEST_ICON} text='Specialty' />
                  <LegendItem image={SAVED_ICON} text='In Review' />
                  <LegendItem image={CHECKED_ICON} text='Completed/Closed' />
                  <LegendItem image={THREE_STRIKE_ICON} text='RTU' />
                </>
              )}
              
              {layoutType === MAP_LAYOUT_TYPES.AppointmentTimeWindow && (
                <>
                  <ColorLegendItem color='#9C27B0' text='Early Morning (before 8 AM)' />
                  <ColorLegendItem color='#4CAF50' text='Morning (8 AM - 10:59 AM)' />
                  <ColorLegendItem color='#2196F3' text='Midday (11 AM - 1:59 PM)' />
                  <ColorLegendItem color='#FF9800' text='Afternoon (2 PM - 4:59 PM)' />
                  <ColorLegendItem color='#F44336' text='Evening (5 PM and later)' />
                  <ColorLegendItem color='#616161' text='No Appointment' />
                </>
              )}
            </div>
          )}
          trigger={
            <Button onClick={() => { }} color='blue' size='huge' circular icon='list alternate outline' />
          }
        />
      </div>
    ) : null}
  </>
);

const mapStateToProps = ({ map }) => {
  return {
    mapStatusId: map.mapStatusId,
    layoutType: map.layoutType
  }
}

export default connect(mapStateToProps, null)(Legend);