import React, { useState, useContext, useEffect } from 'react';

import AppContext from '../../../../../core/context/app.context';

import Loader from '../../../../../shared/components/loader'

import { Dialog } from '@progress/kendo-react-dialogs';

import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { process } from '@progress/kendo-data-query';

import { createBlackout, deleteBlackout, updateBlackout } from '../../../../../core/services/blackout.service';

import './index.css';

// input wrapper style
const iws = { display: 'flex', flexDirection: 'column' }
const formStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: '15px',
    maxWidth: '1000px'
}
const initialForm = {
    blackOutCriteriaValue: '',
    blackOutStart: '',
    blackOutEnd: '',
}

const initialGridState = {
    sort: [],
    filter: [],
    skip: 0,
};

const setDatesMap = (x, index) => ({
    ...x,
    index,
    blackOutStart: new Date(x.blackOutStart),
    blackOutEnd: new Date(x.blackOutEnd)
})



export const EditBlackoutsModal = (props) => {
    const { currentProject, blackouts, getBlackOuts } = useContext(AppContext);
    const [data, setData] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inEditMode, setInEditMode] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [formState, setFormState] = useState(initialForm);
    const [gridState, setGridState] = useState(initialGridState);
    const [originalItem, setOriginalItem] = useState({});

    useEffect(() => {
        const blackoutsToSet = blackouts.map(setDatesMap);
        setData(blackoutsToSet);
        processData(blackoutsToSet);
    }, [blackouts]);

    const processData = (data) => {
        setProcessedData(
            process(data, gridState)
        )
    }

    const handleAddNew = () => {
        if(addNew) setFormState(initialForm);
        setAddNew(prev => !prev);
    };

    const handleSubmitForm = async (evt) => {
        try {
            setLoading(true);
            evt.preventDefault();
            await createBlackout({
                ...formState,
                projectId: currentProject.id,
                blackOutCriteriaName: currentProject.projectConfiguration.blackOutCriteriaName
            });
            await getBlackOuts(currentProject);
            setAddNew(false);
            setFormState(initialForm);
        } catch (error) {
            console.warn('Error in create blackout form');
            console.error(error);
        } finally {
            setLoading(false);
        }
        
    };

    const handleFormState = (evt) => {
        const { target: { name }, value } = evt;
        setFormState(prev => ({ ...prev, [name]: value }));
    }

    const onItemChange = (evt) => {
        const updatedData = JSON.parse(JSON.stringify(data)).map(setDatesMap);
        updatedData[evt.dataItem.index][evt.field] = evt.value;
        processData(updatedData);
        setData(updatedData);
    };

    const handleEdit = (item) => {
        setInEditMode(true);
        setOriginalItem(item);
        const updatedData = JSON.parse(JSON.stringify(data)).map(setDatesMap);
        updatedData[item.index].inEdit = true;
        setData(updatedData);
        processData(updatedData);
    };

    const handleCancel = (item) => {
        setInEditMode(false);
        const updatedData = JSON.parse(JSON.stringify(data)).map(setDatesMap);
        updatedData[item.index].inEdit = false;
        updatedData[item.index] = originalItem;
        setOriginalItem({});
        setData(updatedData);
        processData(updatedData);
    };

    const handleSubmitEdit = async (evt) => {
        try {
            setLoading(true);
            await updateBlackout(evt);
            await getBlackOuts(currentProject);
            setLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setInEditMode(false);
            setLoading(false);
        }
    }

    const handleDelete = async (item) => {
        setLoading(true);
        try {
            // Get the current project's blackOutCriteriaName
            const { blackOutCriteriaName } = currentProject.projectConfiguration;
            
            // Pass the complete blackout item with all required fields
            const blackoutWithRequiredFields = {
                ...item,
                blackOutCriteriaName,
                projectId: currentProject.id
            };
            
            await deleteBlackout(blackoutWithRequiredFields);
            await getBlackOuts(currentProject);
        } catch (error) {
            console.warn('Error deleting blackout', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDataStateChange = (evt) => {
        setGridState(evt.dataState);
        setProcessedData(process(data, evt.dataState));
    }

    return (
        <>
        <Dialog title={'Edit Blackouts'} closeIcon={true} height={'90%'} width={'90%'} onClose={props.closeModal}>
            <div>
                <Button style={{ marginBottom: '15px' }} onClick={handleAddNew} primary={addNew ? false : true} >{addNew ? 'Cancel' : 'Add New Slot'}</Button>
                { addNew && <AddNewCapacityForm formState={formState} handleFormState={handleFormState} onSubmit={handleSubmitForm} /> }
            </div>
            <Grid
                { ...gridState }
                sortable={true}
                filterable={true}
                data={processedData}
                editField='inEdit'
                onItemChange={onItemChange}
                onDataStateChange={handleDataStateChange}
                className='edit-blackout-grid'
            >
                <GridColumn
                    field='blackOutCriteriaValue'
                    title={currentProject.projectConfiguration.blackOutCriteriaName}
                    editable={true}
                />
                <GridColumn
                    field='blackOutStart'
                    title='Start Date'
                    editable={true}
                    filter='date'
                    editor='date'
                    format="{0:MM/dd/yy}"
                />
                <GridColumn
                    field='blackOutEnd'
                    title='End Date'
                    editable={true}
                    filter='date'
                    editor='date'
                    format="{0:MM/dd/yy}"
                />
                <GridColumn
                    sortable={false}
                    filterable={false}
                    cell={(props) => (
                        <td>
                            {props.dataItem.inEdit ? (
                                <>
                                <Button onClick={() => handleSubmitEdit(props.dataItem)} primary={true}>
                                    Save
                                </Button>
                                <Button onClick={() => handleCancel(props.dataItem)}>
                                    Cancel
                                </Button>
                                </>
                            ) : (
                                <>
                                <Button disabled={inEditMode} onClick={() => handleEdit(props.dataItem)} primary={true}>
                                    Edit
                                </Button>
                                <Button disabled={inEditMode} onClick={() => handleDelete(props.dataItem)}>
                                    Delete
                                </Button>
                                </>
                            )}
                        </td>
                    )}
                />
            </Grid>
            { loading && <Loader loading={loading} /> }
        </Dialog>
        </>
    )
}



const AddNewCapacityForm = ({ formState, handleFormState, onSubmit }) => {
    const [valid, setValid] = useState(false);
    useEffect(() => {
        let valid = true;
        if(Object.values(formState).includes(false || '')) valid = false;
        setValid(valid);
    }, [formState]);
    return (
        <div>
            <form onSubmit={onSubmit} style={formStyle}>
                <div style={iws}>
                    <Label>Criteria Value</Label>
                    <Input
                        required={true}
                        className='csr-capacity-inputs'
                        value={formState.blackOutCriteriaValue}
                        name='blackOutCriteriaValue'
                        onChange={handleFormState}
                    />
                </div>
                <div style={iws}>
                    <Label>Start Date</Label>
                    <DatePicker
                        required={true}
                        className='csr-capacity-inputs'
                        value={formState.blackOutStart}
                        name='blackOutStart'
                        onChange={handleFormState}
                    />
                </div>
                <div style={iws}>
                    <Label>End Date</Label>
                    <DatePicker
                        required={true}
                        className='csr-capacity-inputs'
                        value={formState.blackOutEnd}
                        name='blackOutEnd'
                        onChange={handleFormState}
                    />
                </div>
                <div style={iws}>
                    <Button disabled={!valid} onClick={onSubmit} primary={true}>SUBMIT</Button>
                </div>
            </form>
        </div>
    )
}