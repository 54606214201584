import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from 'semantic-ui-react';
import AppContext from '../../../core/context/app.context';
import AddPhotoModal from '../../quality-assurance/components/modal/addPhotoModal';
import CommentModal from '../../../shared/components/modal/comment';
import MeterDeployDetailsModal from '../../../shared/components/workorder-details/meterDeployDetails';
import WaterMeterDeployDetailsModal from '../../../shared/components/workorder-details/waterMeterDeployDetails';
import WaterDeployDetailsModal from '../../../shared/components/workorder-details/waterDeployDetails';
import { orderBy } from '@progress/kendo-data-query';
import MODAL_TYPES from '../../../constants/qaModalTypes';
import WORKORDER_TYPES from '../../../constants/workOrderTypes';


class UtilityReviewUnresolved extends Component {

  static contextType = AppContext;
  _isMounted = false;

  state = {
    sort: [],
    showMeterDeployDetails: false,
    showWaterDeployDetails: false,
    showWaterMeterDeployDetails: false,
    selectedWorkOrderIndex: 0,
    modalDetailsLoading: false,
    showCommentModal: false,
    modalType: '',
    workOrders: [],
    workOrdersLength: 0,
    photoIndex: 0,
    detailsOpenCount: 0
  }

  setState(object) {
    if (this._isMounted) {
      super.setState(object);
    }
  }

  componentWillMount = () => {
    this._isMounted = true;
    const { workOrders, photoType, showAddModal, uploadingNewPhoto } = this.props;
    this.setState({ workOrders, photoType, showAddModal, uploadingNewPhoto });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps = (newProps) => {
    const { workOrders, photoType, showAddModal, uploadingNewPhoto } = newProps;
    this.setState({ workOrders, photoType, showAddModal, uploadingNewPhoto });
  }

  setDetailsOpenCount = (detailsOpenCount) => {
    this.setState({ detailsOpenCount });
  }

  rowClick = async event => {
    const { workOrders } = this.state;
    const { getWorkOrderDetails } = this.props;
    const selectedWorkOrderIndex = workOrders.findIndex(workOrder => workOrder === event.dataItem);
    const selectedWorkOrder = workOrders[selectedWorkOrderIndex];
    await getWorkOrderDetails(selectedWorkOrder.id);
    let newState = {};
    if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.MeterDeploy) {
      newState = { showMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
      newState = { showWaterMeterDeployDetails: true };
    } else if (selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterDeploy || selectedWorkOrder.workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
      newState = { showWaterDeployDetails: true };
    }
    this.setState({ ...newState, selectedWorkOrderIndex });
  }

  handleSearch = (event, data) => {
    const { onSearch } = this.props;
    const searchBy = data.value.toLowerCase();
    onSearch(searchBy);
  }

  closeDetails = () => {
    const { setSelectedWorkOrder } = this.context;
    setSelectedWorkOrder({});
    this.setState({ photoIndex: 0, detailsOpenCount: 0, showMeterDeployDetails: false, showWaterMeterDeployDetails: false, showWaterDeployDetails: false });
  }

  setModalDetailsLoading = (modalDetailsLoading) => {
    this.setState({ modalDetailsLoading });
  }

  onCloseCommentModal = () => {
    this.setState({ showCommentModal: false });
  }

  onOpenCommentModal = () => {
    this.setState({ showCommentModal: true });
  }

  onGallerySlide = (photoIndex) => {
    this.setState({ photoIndex });
  }

  addPhoto = async (event, workOrderId, oldMeterNumber) => {
    const { addPhoto, getWorkOrderDetails } = this.props;
    await addPhoto(event, workOrderId, oldMeterNumber);
    await getWorkOrderDetails(workOrderId);
  }

  onSortChange = (e) => {
    const { workOrders } = this.state;
    const results = orderBy(workOrders, e.sort);
    this.setState({
      sort: e.sort,
      workOrders: results
    });
  }

  openCommentModal = () => {
    this.onOpenCommentModal()
  }

  handleResolve = async (comment) => {
    await this.props.onResolve(this.context.selectedWorkOrder, comment);
    this.closeDetails();
  }

  render() {
    const {
      showMeterDeployDetails,
      showWaterMeterDeployDetails,
      modalDetailsLoading,
      showCommentModal,
      photoIndex,
      showWaterDeployDetails,
      detailsOpenCount,
      modalType
    } = this.state;
    const {
      inReviewLoading,
      showAddPhotoModal,
      onPhotoTypeChange,
      closeAddPhotoModal,
      photoType,
      showAddModal,
      uploadingNewPhoto,
      replaceFileInputRef,
      addFileInputRef,
      user,
      showCustomInputText,
      onCustomTypeChange,
      customPhotoType,
      getWorkOrdersWithStatusInReview,
      qualityAssuranceHeaderLabelColor,
      qualityAssuranceType,
      verifyWorkOrder,
      workOrdersVerified,
      getWorkOrderDetails,
    } = this.props;
    const { currentProject, selectedWorkOrder } = this.context;
    const oldMeterReadingHighThreshold = currentProject.projectConfiguration.oldMeterReadingHighThreshold || null;
    let workOrders = inReviewLoading ? [] : this.state.workOrders;
    return (
      <div className='quality-assurance-in-review'>
        <div className='quality-assurance-tool-bar'>
          {showAddModal && <AddPhotoModal
            customPhotoType={customPhotoType}
            onCustomTypeChange={onCustomTypeChange}
            showModal={showAddModal}
            closeModal={closeAddPhotoModal}
            uploadingNewPhoto={uploadingNewPhoto}
            onSubmit={this.addPhoto}
            photoType={photoType}
            onPhotoTypeChange={onPhotoTypeChange}
            fileInputRef={addFileInputRef}
            selectedWorkOrder={selectedWorkOrder}
            showCustomInputText={showCustomInputText} />}
          {showCommentModal && <CommentModal
            modalType={modalType}
            submitWithoutComment={modalType === MODAL_TYPES.REJECT}
            showEscalationCategories={true}
            workOrderId={selectedWorkOrder.id}
            userId={user.id}
            showModal={showCommentModal}
            onSubmit={this.handleResolve}
            closeModal={this.onCloseCommentModal} />}
          {showWaterDeployDetails && <WaterDeployDetailsModal
            onResolve={this.openCommentModal}
            setModalDetailsLoading={this.setModalDetailsLoading}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            currentProject={currentProject}
            getWorkOrdersWithStatusInReview={getWorkOrdersWithStatusInReview}
            getWorkOrderDetails={getWorkOrderDetails}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={false}
            isUtilityReview={true}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            showModal={showWaterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            fileInputRef={replaceFileInputRef}
            showAddPhotoModal={showAddPhotoModal}
            workOrdersVerified={workOrdersVerified}
            verifyWorkOrder={verifyWorkOrder} />}
          {showWaterMeterDeployDetails && <WaterMeterDeployDetailsModal
            onResolve={this.openCommentModal}
            setModalDetailsLoading={this.setModalDetailsLoading}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            getWorkOrdersWithStatusInReview={getWorkOrdersWithStatusInReview}
            getWorkOrderDetails={getWorkOrderDetails}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={false}
            isUtilityReview={true}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            currentProject={currentProject}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            showModal={showWaterMeterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            fileInputRef={replaceFileInputRef}
            showAddPhotoModal={showAddPhotoModal}
            workOrdersVerified={workOrdersVerified}
            verifyWorkOrder={verifyWorkOrder} />}
          {showMeterDeployDetails && <MeterDeployDetailsModal
            onResolve={this.openCommentModal}
            setModalDetailsLoading={this.setModalDetailsLoading}
            setDetailsOpenCount={this.setDetailsOpenCount}
            detailsOpenCount={detailsOpenCount}
            qualityAssuranceType={qualityAssuranceType}
            isQualityAssurance={false}
            isUtilityReview={true}
            qualityAssuranceHeaderLabelColor={qualityAssuranceHeaderLabelColor}
            getWorkOrdersWithStatusInReview={getWorkOrdersWithStatusInReview}
            getWorkOrderDetails={getWorkOrderDetails}
            currentProject={currentProject}
            oldMeterReadingHighThreshold={oldMeterReadingHighThreshold}
            fileInputRef={replaceFileInputRef}
            showAddPhotoModal={showAddPhotoModal}
            onGallerySlide={this.onGallerySlide}
            photoIndex={photoIndex}
            showModal={showMeterDeployDetails}
            closeModal={this.closeDetails}
            selectedWorkOrder={selectedWorkOrder}
            workOrders={workOrders}
            modalDetailsLoading={modalDetailsLoading}
            user={user}
            workOrdersVerified={workOrdersVerified}
            verifyWorkOrder={verifyWorkOrder} />}
          <Input placeholder='Search...' icon='search' fluid style={{ flex: 1 }} onChange={this.handleSearch} />
        </div>
        <Grid
          className='quality-assurance-in-review-grid'
          sortable={{
            mode: 'single'
          }}
          sort={this.state.sort}
          onSortChange={this.onSortChange}
          style={{ height: '90%' }}
          data={workOrders}
          onRowClick={this.rowClick}>
          <Column field='oldMeterNumber' title='Old Meter' width='140px' />
          <Column field='meterStatus' title='Meter Status' width='120px' />
          <Column field='workOrderLastVisitDate' sortable={false} title='Last Visit' width='160px' />
          <Column field='workOrderResourceUser' title='Technician' width='200px' />
          <Column field='districtCode' title='District' width='120px' />
          <Column field='newMeterNumber' title='New Meter' width='160px' />
          <Column field='newMeterAssetNumber' title='Asset' width='160px' />
          <Column field='newRadioNumber' title='New Radio' width='160px' />
          <Column field='meterReadNotes' title='Read Notes' width='200px' />
          <Column field='workOrderAttemptNumber' title='Attempt' width='120px' />
          <Column field='workOrderFlaggedAsCNC' title='CNC' width='120px' />
          <Column field='workOrderFlaggedAs3strike' title='3 Strike' width='120px' />
          <Column field='workOrderFlaggedAsDamaged' title='Damaged' width='130px' />
          <Column field='workOrderFlaggedAsAdHoc' title='Ad Hoc' width='120px' />
          <Column field='workOrderIsInBlackOut' title='Blackout' width='140px' />
        </Grid>
      </div>
    );
  }
}

export default UtilityReviewUnresolved;