import gql from "graphql-tag";

export const LIST_WORKORDERS_BY_TYPE_AND_STATUS_QUERY = (
  workOrderType,
  workOrderStatus
) => gql`
  query ListWorkOrdersByWorkOrderTypeAndWorkOrderStatus($projectId: ID!, $limit: Int, $offset: Int) {
    listWorkOrdersByWorkOrderTypeAndWorkOrderStatus (project: $projectId, workOrderType: ${workOrderType}, workOrderStatus: ${workOrderStatus}, limit: $limit, offset: $offset) {
      items {
        id
        oldMeterNumber
        workOrderStatus
        workOrderType
        workOrderLastVisitDate
        workOrderAssignments
        workOrderFlaggedAsCNC
        workOrderFlaggedAs3strike
        workOrderFlaggedAsAdHoc
        workOrderFlaggedAsDamaged
        workOrderAttemptNumber
        workOrderFlaggedAsDamaged
        workOrderIsInBlackOut
        districtCode
        newRadioNumber
        newMeterNumber
        workOrderNumber
        meterReadNotes
        workOrderReasonCodes
        workOrderResourceUser
        meterStatus
        newMeterAssetNumber
        customDataFields {
          key
          value
          type
        }
      }
    }
  }
`;

export const LIST_WORORDERS_FOR_UTILITY_REVIEW = gql`
  query ListWorkOrdersForUtilityReview($projectId: ID!, $limit: Int, $offset: Int, $workOrderType: WorkOrderType!) {
    listWorkOrdersForUtilityReview (project: $projectId, workOrderType: $workOrderType, limit: $limit, offset: $offset) {
      items {
        id
        oldMeterNumber
        workOrderStatus
        workOrderType
        workOrderLastVisitDate
        workOrderAssignments
        workOrderFlaggedAsCNC
        workOrderFlaggedAs3strike
        workOrderFlaggedAsAdHoc
        workOrderFlaggedAsDamaged
        workOrderAttemptNumber
        workOrderFlaggedAsDamaged
        workOrderIsInBlackOut
        districtCode
        newRadioNumber
        newMeterNumber
        workOrderNumber
        meterReadNotes
        workOrderReasonCodes
        workOrderResourceUser
        meterStatus
        newMeterAssetNumber
        customDataFields {
          key
          value
          type
        }
      }
    }
  }
`;

export const LIST_CSR_PORTAL_QUERY = gql`
  query ListCsrPortalWorkOrders($projectId: ID!, $offset: Int) {
    listCsrPortalWorkOrders (project: $projectId, offset: $offset, limit: 1000) {
      items {
        id
        street
        street2
        street3
        city
        stateCode
        zipCode
        mobilePhoneNumber
        homePhoneNumber
        businessPhoneNumber
        customerName
        workOrderType
        workOrderNumber
        workOrderStatus
        workOrderAttemptNumber
        workOrderLastVisitDate
        workOrderIsInBlackOut
        workOrderAssignments
        accountNumber
        workOrderGroups
        workOrderNeedsAppointment
        workOrderFlaggedAs3strike
        workOrderFlaggedAsCNC
        workOrderFlaggedAsAdHoc
        districtCode
        bookCode
        appointmentStartDate
        customDataFields {
          key
          value
          type
        }
      }
    }
  }
`;

export const LIST_WORKORDERS_BY_TYPE_QUERY = gql`
  query ListWorkOrdersByWorkOrderType(
    $projectId: ID!
    $limit: Int
    $offset: Int
    $search: String
    $filter: [String]
    $multipleValuesSearch: [String]
    $workOrderType: WorkOrderType!
  ) {
    listWorkOrdersByWorkOrderType(
      project: $projectId
      limit: $limit
      offset: $offset
      search: $search
      filter: $filter
      multipleValuesSearch: $multipleValuesSearch
      workOrderType: $workOrderType
    ) {
      resultCount
      items {
        id
        oldMeterNumber
        workOrderNumber
        workOrderStatus
        workOrderAttemptNumber
        workOrderLastVisitDate
        workOrderFlaggedAsCNC
        workOrderFlaggedAs3strike
        workOrderFlaggedAsAdHoc
        workOrderFlaggedAsDamaged
        workOrderFlaggedAsPlated
        workOrderIsInBlackOut
        meterFormFactor
        districtCode
        substationCode
        circuitCode
        billingCycleCode
        routeCode
        bookCode
        street
        workOrderGroups
        workOrderAssignments
        workOrderFlaggedAsEscalated
        latitude
        longitude
        newMeterNumber
        locationId
        accountNumber
        customerName
        workOrderNeedsAppointment
        workOrderNeedsSiteTest
        newMeterAssetNumber
        newRadioNumber
        ReadSequence
        meterSize
        appointmentStartDate
        city
      }
    }
  }
`;

export const LIST_WORKORDERS_BY_TENANT_QUERY = gql`
  query ListWorkOrdersByTenant(
    $tenant: ID!
    $limit: Int
    $offset: Int
    $search: String
  ) {
    listWorkOrdersByTenant(
      tenant: $tenant
      limit: $limit
      offset: $offset
      search: $search
    ) {
      items {
        id
        workOrderType
        workOrderNumber
        workOrderStatus
        workOrderAttemptNumber
        workOrderLastVisitDate
        workOrderFlaggedAsCNC
        workOrderFlaggedAs3strike
        workOrderFlaggedAsAdHoc
        workOrderFlaggedAsDamaged
        workOrderFlaggedAsPlated
        workOrderIsInBlackOut
        districtCode
        substationCode
        circuitCode
        billingCycleCode
        routeCode
        bookCode
        street
        workOrderAssignments
        workOrderFlaggedAsEscalated
        locationId
        accountNumber
        customerName
        workOrderNeedsAppointment
        workOrderNeedsSiteTest
        newMeterAssetNumber
      }
    }
  }
`;

export const LIST_WORKORDERS_BY_CUSTOMER_INFORMATION = gql`
  query ListWorkOrdersByCustomerInformation(
    $tenant: ID!
    $limit: Int
    $offset: Int
    $search: String!
  ) {
    listWorkOrdersByCustomerInformation(
      tenant: $tenant
      limit: $limit
      offset: $offset
      search: $search
    ) {
      items {
        id
        street
        street2
        street3
        city
        stateCode
        zipCode
        mobilePhoneNumber
        homePhoneNumber
        businessPhoneNumber
        customerName
        workOrderType
        workOrderNumber
        workOrderStatus
        workOrderAttemptNumber
        workOrderLastVisitDate
        workOrderIsInBlackOut
        workOrderAssignments
        accountNumber
        workOrderNeedsAppointment
        serviceCode
        project {
          projectShortCode
          projectName
        }
      }
    }
  }
`;

export const LIST_WORKORDERS_STATUS_COUNTS_QUERY = gql`
  query ListWorkOrderStatusCounts($projectId: ID!) {
    listWorkOrderStatusCounts(project: $projectId) {
      Open
      Assigned
      InProgress
      InReview
      Escalated
      Completed
      Closed
      Total
    }
  }
`;

export const GET_WORKORDER_BY_ID = gql`
  query GetWorkOrder($id: ID!) {
    getWorkOrder(id: $id) {
      id
      customDataFields {
        key
        value
        type
      }
      auditDataFields {
        key
        value
        type
      }
      recordLocator
      mapNumber
      billingMultiplier
      workOrderClosedDate
      workOrderIsGeocoded
      workOrderNeedsAppointment
      workOrderNeedsSiteTest
      workOrderSiteConditions
      oldMeterNumber
      workOrderType
      meterStatus
      meterType
      workOrderStatus
      workOrderLastVisitDate
      workOrderFlaggedAsCNC
      workOrderFlaggedAs3strike
      workOrderFlaggedAsAdHoc
      workOrderFlaggedAsDamaged
      workOrderFlaggedAsPlated
      workOrderFlaggedAsEscalated
      workOrderEscalatedCategory
      workOrderIsInBlackOut
      workOrderReasonCodes
      workOrderAuditDate
      meterFormFactor
      districtCode
      substationCode
      circuitCode
      billingCycleCode
      routeCode
      bookCode
      newMeterNumber
      street
      city
      zipCode
      stateCode
      serviceCode
      workOrderAssignments
      workOrderGroups
      workOrderNumber
      oldMeterKwLastReading
      oldMeterKwhLastReading
      oldMeterKvaLastReading
      oldMeterKvarLastReading
      oldMeterKwReading
      oldMeterKwhReading
      oldMeterKvaReading
      oldMeterKvarReading
      workOrderResourceUserId
      newMeterKwhReading
      newRadioValidated
      newRegisterNotDeployed
      newLidSize
      lidSize
      meterSize
      oldRegisterNumber
      newRegisterNumber
      oldMeterLastReading
      oldMeterReading
      lineType
      customerContactedOnSite
      oldRadioNumber
      newRadioNumber
      customerName
      serviceType
      meterClass
      meterVoltage
      locationId
      serviceDescription
      locationId
      accountNumber
      homePhoneNumber
      mobilePhoneNumber
      businessPhoneNumber
      meterReadNotes
      newMeterAssetNumber
      workOrderDeletedDate
      ReadSequence
      workOrderNeedsSiteTest
      latitude
      longitude
      capturedLatitude
      capturedLongitude
      capturedGpsPositionSource
      capturedGpsHorizontalAccuracy
      newMeterNotDeployed
      newRadioNotDeployed
      newMeterReading
      isMedical
      needsOpenDisconnectSwitch
      project {
        id
        projectTimeZone
        projectName
      }
      photos {
        items {
          id
          visibility
          photoDate
          name
          owner
          photoType
          file {
            bucket
            region
            key
          }
        }
      }
      workOrderComments {
        items {
          content
          contentDate
          user {
            userName
          }
        }
      }
      workOrderHistorys {
        items {
          historyContent
          historyDate
          historyUser {
            userName
          }
        }
      }
    }
  }
`;

export const GET_SERVICE_CODE_BY_WORKORDER = gql`
  query GetServiceCodeByWorkOrder($id: ID!) {
    getServiceCodeByWorkOrder(id: $id) {
      serviceCode
    }
  }
`;

export const LIST_WORKORDER_BY_PROJECT_QUERY = gql`
  query ListWorkOrdersByProject($projectId: ID!, $limit: Int, $offset: Int) {
    listWorkOrders(project: $projectId, limit: $limit, offset: $offset) {
      items {
        id
        workOrderNumber
        workOrderType
        workOrderStatus
        workOrderFlaggedAsCNC
        workOrderFlaggedAs3strike
        workOrderFlaggedAsDamaged
        workOrderFlaggedAsAdHoc
        workOrderFlaggedAsPlated
        workOrderFlaggedAsEscalated
        workOrderIsInBlackOut
        workOrderAttemptNumber
        workOrderLastVisitDate
        workOrderReasonCodes
        workOrderSiteConditions
        workOrderAssignments
        workOrderResourceUserId
        workOrderReviewedUserId
        workOrderGroups
        districtCode
        substationCode
        circuitCode
        billingCycleCode
        routeCode
        bookCode
        
        accountNumber
        customerName
        homePhoneNumber
        mobilePhoneNumber
        businessPhoneNumber
        street
        street2
        street3
        city
        stateCode
        zipCode

        locationId
        mapNumber
        latitude
        longitude
        capturedGpsAltitude
        capturedGpsHorizontalAccuracy
        capturedGpsPositionSource
        capturedGpsVerticalAccuracy
        capturedLatitude
        capturedLongitude

        oldMeterNumber
        oldMeterKwLastReading
        oldMeterKwhLastReading
        oldMeterKvaLastReading
        oldMeterKvarLastReading
        oldMeterKwReading
        oldMeterKwhReading
        oldMeterKvaReading
        oldMeterKvarReading
        newMeterNumber
        newMeterScan
        newMeterKwReading
        newMeterKwhReading
        newMeterKvaReading
        newMeterKvarReading
        newMeterDial
        meterReadingDate
        newMeterReadingDate
        meterStatus
        meterType
        meterClass
        meterVoltage
        meterFormFactor
        meterReadNotes
        serviceType
        serviceDescription
        ReadSequence
        isMedical
        needsOpenDisconnectSwitch
        hasDisconnectedSwitch
        meterSetNumber
        demandCode
      }
    }
  }
`;

export const GET_WORKORDER_FILTER_CRITERIA_QUERY = gql`
  query ListWorkOrderFilterCriteria($projectId: ID!) {
    listWorkOrderFilterCriteria(project: $projectId) {
      workOrderStatusFilterItems
      districtCodeFilterItems
      substationCodeFilterItems
      circuitCodeFilterItems
      billingCycleCodeFilterItems
      routeCodeFilterItems
      bookCodeFilterItems
      meterFormFactorFilterItems
      meterSizeFilterItems
      groupFilterItems
      userNameFilterItems
      workOrderCustomFilterItems
    }
  }
`;

export const LIST_WORKORDER_STATUS_BY_ROUTE_QUERY = gql`
  query listWorkOrderStatusByRoute($projectId: ID!) {
    listWorkOrderStatusByRoute(project: $projectId) {
      items {
        routeCode
        Open
        Assigned
        InProgress
        InReview
        Escalated
        Completed
        Closed
        RTU
        Deleted
        RouteTotal
      }
    }
  }
`;

export const LIST_METER_SIZE_BY_ROUTE_QUERY = gql`
  query listMeterSizeByRoute($projectId: ID!) {
    listMeterSizeByRoute(project: $projectId) {
      hasMeter
      hasRegister
      hasRadio
      meterColumnNames
      registerColumnNames
      routes
      meterAvailable
      registerAvailable
      radioAvailable
      meterCounts {
        counts
      }
      registerCounts {
        counts
      }
      radioCounts {
        counts
      }
    }
  }
`;

export const LIST_WEEKLY_PRODUCTION_STATUS = gql`
  query ListWeeklyProductionStatus($projectId: ID!, $week: Int) {
    listWeeklyProductionStatus(project: $projectId, week: $week) {
      items {
        userName
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        total
      }
    }
  }
`;

export const LIST_DAILY_PRODUCTION_STATUS_BY_METER_TYPE = gql`
  query ListDailyProductionStatusByMeterType($projectId: ID!) {
    listDailyProductionStatusByMeterType(project: $projectId) {
      columnNames
      technicians
      hasMeter
      items {
        counts
      }
    }
  }
`;

export const LIST_DAILY_PRODUCTION_STATUS_BY_REGISTER_TYPE = gql`
  query ListDailyProductionStatusByRegisterType($projectId: ID!) {
    listDailyProductionStatusByRegisterType(project: $projectId) {
      columnNames
      technicians
      hasRegister
      items {
        counts
      }
    }
  }
`;

export const LIST_DAILY_PRODUCTION_STATUS_BY_RADIO_TYPE = gql`
  query ListDailyProductionStatusByRadioType($projectId: ID!) {
    listDailyProductionStatusByRadioType(project: $projectId) {
      columnNames
      technicians
      hasRadio
      items {
        counts
      }
    }
  }
`;


export const LIST_DAILY_PRODUCTION_STATUS_BY_NON_SERIALIZED_INVENTORY_QUERY = gql`
  query ListDailyProductionStatusByNonSerializedInventory($projectId: ID!) {
    listDailyProductionStatusByNonSerializedInventory(project: $projectId) {
      assetTypes
      assetTypeColumns {
        columns
      }
      assetTypeItems {
        items {
          counts
        }
      }
      technicians
    }
  }
`;


export const LIST_WORKORDER_GROUPS_QUERY = gql`
  query ListWorkOrderGroups($project: ID!, $limit: Int, $offset: Int) {
    listWorkOrderGroups(project: $project, limit: $limit, offset: $offset) {
      items
    }
  }
`;

export const LIST_WORKORDER_ROUTE_CODES_QUERY = gql`
  query ListWorkOrderRouteCodes($project: ID!) {
    listWorkOrderRouteCodes(project: $project) {
      items {
        route
        totalCount
        remainingCount
        percentageDone
        billingCycleCode
      }
    }
  }
`;

export const LIST_WORKORDERS_MAP_LAYOUT_DATA_QUERY = gql`
  query ListWorkOrdersMapLayoutData($project: ID!, $workOrderType: WorkOrderType!) {
    listWorkOrdersMapLayoutData(project: $project, workOrderType: $workOrderType) {
      routeCodes
      billingCycleCodes
      substationCodes
      circuitCodes
      districtCodes
      workOrderAssignments
      formSizes
    }
  }
`;

export const GET_WORKORDER_INVALID_LOCATION_COUNT_QUERY = gql`
  query getWorkOrderInvalidLocationCount($project: ID!) {
    getWorkOrderInvalidLocationCount(project: $project) {
      count
    }
  }
`;

export const GET_SITE_TEST_RESULTS_QUERY = gql`
  query GetSiteTestResults($projectId: ID!, $resultVersion: String) {
    getSiteTestResults(projectId: $projectId, resultVersion: $resultVersion) {
      Pass
      Fail
      NoSecondaryCurrent
      NotRequested
      Blank
    }
  }
`;

export const GET_METER_TEST_RESULTS_QUERY = gql`
  query GetMeterTestResults($projectId: ID!) {
    getMeterTestResults(projectId: $projectId) {
      Pass
      Fail
      NotRequested
      Blank
    }
  }
`;

export const GET_WORKORDER_BY_OLD_METER_NUMBER_QUERY = gql`
  query GetWorkOrderByOldMeterNumber($projectId: ID, $workOrderType: WorkOrderType, $oldMeterNumber: String!) {
    getWorkOrderByOldMeterNumber(project: $projectId, workOrderType: $workOrderType, oldMeterNumber: $oldMeterNumber) {
      id
      workOrderNeedsAppointment
    }
  }
`;