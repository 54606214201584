import routeMaps from '../../route-maps/route-maps';
import WorkOrdersRoute from '../../../routes/workorders';
import QualityAssuranceRoute from '../../../routes/quality-assurance';
import InventoryRoute from '../../../routes/inventory';
import ProjectTechnicianReportRoute from '../../../routes/technician-report';
import InventoryReportRoute from '../../../routes/inventory-report';
import WorkOrdersReportRoute from '../../../routes/workorder-report';
import ConditionsReportRoute from '../../../routes/conditions-report';
import ExceptionsReportRoute from '../../../routes/exceptions-report';
import TechnicianReportRoute from '../../../routes/administration/technician-report';
import WhatsNewRoute from '../../../routes/whats-new';
import AssignmentsReportRoute from '../../../routes/administration/assignments-report';
import AppointmentsReportRoute from '../../../routes/appointments-report';
import DashboardRoute from '../../../routes/dashboard';
import UserManagementRoute from '../../../routes/administration/user-management';
import InventoryImportAssets from '../../../routes/inventory-import-assets';
import AdministrationOverviewRoute from '../../../routes/administration/overview';
import AdministrationProjectsRoute from '../../../routes/administration/projects';
import AdministrationProjectOverviewRoute from '../../../routes/administration/project-overview';
import PayrollReportRoute from '../../../routes/administration/payroll-report';
import AnalysisChartsViewRoute from '../../../routes/analysis-charts-view';
import AnalysisMapViewRoute from '../../../routes/analysis-map-view';
import PlanningRoute from '../../../routes/planning';
import AdministrationUpdateProjectRoute from '../../../routes/administration/update-project';
import NonSerializedAssetsRoute from '../../../routes/inventory-non-serialized-assets';
import ProjectToolsServiceType from '../../../routes/google-map';
import AppointmentRoute from '../../../routes/appointment';
import QualificationManagementRoute from '../../../routes/administration/qualification-management';
import SnapshotsRoute from '../../../routes/snapshots';
import PeopleEmailReportingRoute from '../../../routes/people/email-reporting';
import CSRRoute from '../../../shared/components/query';
import UtilityReview from '../../../routes/utility-review';

export default [
  {
    comp: ProjectToolsServiceType,
    path: routeMaps.projectToolsServiceType,
    exact: true
  },
  {
    comp: AdministrationProjectsRoute,
    path: routeMaps.administrationProjects,
    exact: true,
  },
  {
    comp: AdministrationOverviewRoute,
    path: routeMaps.administrationOverview,
    exact: true,
  },
  {
    comp: AdministrationProjectOverviewRoute,
    path: routeMaps.administrationProjectOverview,
    exact: true,
  },
  {
    comp: DashboardRoute,
    path: routeMaps.dashboard,
    exact: true,
  },
  {
    comp: WorkOrdersRoute,
    path: routeMaps.workOrders,
    exact: true,
  },
  {
    comp: DashboardRoute,
    path: routeMaps.dashboardExternalLink,
    exact: true,
  },
  {
    comp: QualityAssuranceRoute,
    path: routeMaps.qualityAssurance,
    exact: true,
  },
  {
    comp: InventoryRoute,
    path: routeMaps.inventory,
    exact: true,
  },
  {
    comp: InventoryReportRoute,
    path: routeMaps.inventoryReport,
    exact: true,
  },
  {
    comp: WorkOrdersReportRoute,
    path: routeMaps.workOrdersReport,
    exact: true,
  },
  {
    comp: ConditionsReportRoute,
    path: routeMaps.conditionsReport,
    exact: true,
  },
  {
    comp: ExceptionsReportRoute,
    path: routeMaps.exceptionsReport,
    exact: true,
  },
  {
    comp: ProjectTechnicianReportRoute,
    path: routeMaps.technicianReport,
    exact: true,
  },
  {
    comp: TechnicianReportRoute,
    path: routeMaps.administrationTechnicianReport,
    exact: true,
  },
  {
    comp: AssignmentsReportRoute,
    path: routeMaps.administrationAssignmentsReport,
    exact: true
  },
  {
    comp: PayrollReportRoute,
    path: routeMaps.administrationPayrollReport,
    exact: true
  },
  {
    comp: UserManagementRoute,
    path: routeMaps.userManagement,
    exact: true,
  },
  {
    comp: InventoryImportAssets,
    path: routeMaps.importAssets,
    exact: true,
  },
  {
    comp: AnalysisChartsViewRoute,
    path: routeMaps.analysisChartsView,
    exact: true
  },
  {
    comp: AnalysisMapViewRoute,
    path: routeMaps.analysisMapView,
    exact: true
  },
  {
    comp: PlanningRoute,
    path: routeMaps.planning,
    exact: true
  },
  {
    comp: AdministrationUpdateProjectRoute,
    path: routeMaps.administrationUpdateProject,
    exact: true
  },
  {
    comp: InventoryRoute,
    path: routeMaps.serializedAssets,
    exact: true
  },
  {
    comp: NonSerializedAssetsRoute,
    path: routeMaps.nonSerializedAssets,
    exact: true
  },
  {
    comp: AppointmentRoute,
    path: routeMaps.appointment,
    exact: true
  },
  {
    comp: SnapshotsRoute,
    path: routeMaps.snapshots,
    exact: true
  },
  {
    comp: QualificationManagementRoute,
    path: routeMaps.qualificationManagement,
    exact: true
  },
  {
    comp: AppointmentsReportRoute,
    path: routeMaps.appointmentsReport,
    exact: true
  },
  {
    comp: PeopleEmailReportingRoute,
    path: routeMaps.peopleEmailReporting,
    exact: true
  },
  {
    comp: WhatsNewRoute,
    path: routeMaps.whatsNew,
    exact: true,
  },
  {
    comp: CSRRoute,
    path: routeMaps.csrPortal,
    exact: true,
  },
  {
    comp: UtilityReview,
    path: routeMaps.utilityReview,
    exact: true,
  }
]