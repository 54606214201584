import React from 'react';
import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels
} from '@progress/kendo-react-charts';

const labelContent = (e) => (e.category);

const donutCenterRenderer = (total) => (<span><h3>{total}</h3> Total</span>);

const DonutChart = ({ data, utilityReviewEnabled, utilityReviewCount, utilityResolvedCount }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Chart donutCenterRender={() => donutCenterRenderer(data.total)} seriesColors={['#80868b', '#42981F', '#DC8912', '#0089E7', '#C84F0A', '#D0021B', '#F0C219']}>
      <ChartTitle text="Workflow Status" />
      <ChartSeries>
        <ChartSeriesItem type="donut" data={data.listWorkOrderStatusCounts} categoryField="name" field="value">
          <ChartSeriesLabels position="outsideEnd" background="none" content={labelContent} />
        </ChartSeriesItem>
      </ChartSeries>
      <ChartLegend visible={true} position='bottom' orientation='horizontal' />
    </Chart>
    {utilityReviewEnabled && (
      <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}>
        In Progress includes: Utility Review ({utilityReviewCount}) and Utility Resolved ({utilityResolvedCount})
      </div>
    )}
  </div>
);

function arePropsEqual(oldProps, newProps) {
  return JSON.stringify(oldProps) === JSON.stringify(newProps);
}

export default React.memo(DonutChart, arePropsEqual);