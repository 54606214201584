import client from '../apollo';
import { GET_BLACKOUTS_QUERY, IS_IN_BLACKOUT_RANGE_QUERY } from '../../graphql/queries/blackout';
import { CREATE_BLACKOUT_MUTATION, DELETE_BLACKOUT_MUTATION, UPDATE_BLACKOUT_MUTATION } from '../../graphql/mutations/blackout';

export const getBlackOuts = async (projectId) => {
  try {
    const { data } = await client.query({
      query: GET_BLACKOUTS_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        projectId,
        limit: 1000
      }
    });
    return data && data.listBlackOuts && data.listBlackOuts.items && data.listBlackOuts.items.length > 0 ? data.listBlackOuts.items : [];
  } catch (error) {
    console.log(error);
  }
}

export const isInBlackOutRange = async (project, routes, startDates, endDates, billingCycleCodes) => {
  try {
    const { data } = await client.query({
      query: IS_IN_BLACKOUT_RANGE_QUERY,
      variables: {
        project,
        routes,
        startDates,
        endDates,
        billingCycleCodes
      }
    });
    if (data && data.isInBlackOutRange && data.isInBlackOutRange.routes && data.isInBlackOutRange.isBlackout) {
      const { routes, isBlackout } = data.isInBlackOutRange;
      const results = [];
      routes.forEach((route, index) => results.push({ route: route.toString(), isBlackout: isBlackout[index] }));
      return results;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}

export const createBlackout = async (variables) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_BLACKOUT_MUTATION,
      variables,
    });
    if (data && data.createBlackout) {
      return data
    }
    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updateBlackout = async (variables) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_BLACKOUT_MUTATION,
      variables,
    });
    if (data && data.updateBlackout) {
      return data
    }
    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deleteBlackout = async (blackout) => {
  try {
    // Extract all the required fields from the blackout object
    const { id, blackOutStart, blackOutEnd, blackOutCriteriaValue, blackOutCriteriaName, projectId } = blackout;
    
    // Make a copy of dates to avoid mutating the original objects
    let formattedStartDate, formattedEndDate;
    
    // Determine the type of date and handle appropriately
    if (blackOutStart instanceof Date) {
      formattedStartDate = blackOutStart.toISOString();
    } else if (typeof blackOutStart === 'string') {
      // If it's already a string, use it directly if it looks like ISO format
      formattedStartDate = blackOutStart.includes('T') ? blackOutStart : new Date(blackOutStart).toISOString();
    } else {
      // Handle edge case where date might be in an unexpected format
      formattedStartDate = new Date(blackOutStart).toISOString();
    }
    
    if (blackOutEnd instanceof Date) {
      formattedEndDate = blackOutEnd.toISOString();
    } else if (typeof blackOutEnd === 'string') {
      formattedEndDate = blackOutEnd.includes('T') ? blackOutEnd : new Date(blackOutEnd).toISOString();
    } else {
      formattedEndDate = new Date(blackOutEnd).toISOString();
    }
    
    const { data } = await client.mutate({
      mutation: DELETE_BLACKOUT_MUTATION,
      variables: {
        id,
        blackOutStart: formattedStartDate,
        blackOutEnd: formattedEndDate,
        blackOutCriteriaValue,
        blackOutCriteriaName,
        projectId
      },
    });
    if (data && data.deleteBlackout) {
      return data
    }
    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
}