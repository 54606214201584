import React, { useContext, useEffect } from 'react';
import { Button, Checkbox, Icon } from 'semantic-ui-react';
import { isEnabled } from '../../../core/services/auth.service';
import AppContext from '../../../core/context/app.context';
import ROLES from '../../../constants/roles';
import TAB_TYPE from '../../../constants/qaModalTypes';





const DetailsActionsManager = (props) => {
  // Create a sanitized props object with default values for all required props
  const safeProps = {
    ...props,
    isUtilityReview: !!props.isUtilityReview,
    isQualityAssurance: !!props.isQualityAssurance,
    // Make sure other commonly used props have default values
    currentProject: props.currentProject || {},
    selectedWorkOrder: props.selectedWorkOrder || {},
    user: props.user || {},
    modalDetailsLoading: !!props.modalDetailsLoading,
    closeModal: props.closeModal || (() => {}),
    onResolve: props.onResolve || (() => {}),
    showAddPhotoModal: props.showAddPhotoModal || (() => {})
  };
  
  if(safeProps.isUtilityReview){
    return <UtilityReviewActionsBar {...safeProps} />
  } else if(safeProps.isQualityAssurance){
    return <QualityAssuranceDetailsActions {...safeProps} />
  } else {
    return <DefaultDetailsActions {...safeProps} />
  }
}



const UtilityReviewActionsBar = ({
  showAddPhotoModal = () => {},
  selectedWorkOrder = {},
  modalDetailsLoading = false,
  onResolve = () => {},
  user = {},
  closeModal = () => {},
}) => {
  const context = useContext(AppContext);
  const resolveAvailable = onResolve && selectedWorkOrder && selectedWorkOrder.workOrderReasonCodes && selectedWorkOrder.workOrderReasonCodes.indexOf('RTU Review') >= 0;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        {isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin]) && <Button onClick={() => showAddPhotoModal(selectedWorkOrder.id, selectedWorkOrder.oldMeterNumber)} color='orange'>
          Add Photo
        </Button>}
      </div>
      <div style={{ display: 'flex' }}>
        {resolveAvailable && <Button color='yellow' onClick={() => onResolve(selectedWorkOrder)}>
          Resolve
        </Button>}
        <Button color='grey' onClick={() => context.setModals({ assignmentModal: true })}>
          Assign
        </Button>
        <Button color='red' disabled={modalDetailsLoading} onClick={() => closeModal()}>
          Close
        </Button>
      </div>
    </div>
  )
}



const DefaultDetailsActions = ({ 
  onNext = () => {}, 
  closeModal = () => {}, 
  hideNext = false 
}) => (
  <div>
    {onNext && !hideNext && <Button color='grey' onClick={() => onNext()}>Next</Button>}
    <Button primary onClick={closeModal}>Close <Icon name='chevron right' /></Button>
  </div>
);



const QualityAssuranceDetailsActions = ({
  fileInputRef = { current: null },
  onReplacePhotoClick = () => {},
  replacePhoto = () => {},
  showAddPhotoModal = () => {},
  selectedWorkOrder = {},
  handleEscalate = () => {},
  handleReject = () => {},
  handleApprove = () => {},
  disableApprove = false,
  modalDetailsLoading = false,
  workOrders = [],
  handleApproveAndNext = () => {},
  onNext = () => {},
  user = {},
  type = '',
  closeWorkOrderUponApproval = false,
  onCloseWorkOrderUponApprovalChange = () => {},
  closeModal = () => {},
}) => {
  let isRework = false;
  if (selectedWorkOrder && selectedWorkOrder.customDataFields) {
    isRework = selectedWorkOrder.customDataFields.findIndex(({ key }) => key === 'reworkReason') > -1;
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex' }}>
        {type !== TAB_TYPE.COMPLETED && (
          <>
            {isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin]) && <Button onClick={onReplacePhotoClick} type='file' color='orange'>
              Replace Photo
            </Button>}
            <input accept="image/jpg" ref={fileInputRef} type='file' hidden onChange={async (event) => replacePhoto(event)} />
            {isEnabled(user.userRoles, [ROLES.SystemAdmin, ROLES.AccountAdmin, ROLES.Admin]) && <Button onClick={() => showAddPhotoModal(selectedWorkOrder.id, selectedWorkOrder.oldMeterNumber)} color='orange'>
              Add Photo
            </Button>}
          </>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        {type === TAB_TYPE.ESCALATED && isRework && <div style={{ display: 'flex', flexDirection: 'column', marginRight: 15, justifyContent: 'center' }}><div><Checkbox onChange={onCloseWorkOrderUponApprovalChange} checked={closeWorkOrderUponApproval} label='Close the work order upon approval.' /></div></div>}
        {type !== TAB_TYPE.ESCALATED && <Button color='yellow' disabled={modalDetailsLoading} onClick={() => handleEscalate()}>
          Escalate
        </Button>}
        <Button color='red' disabled={modalDetailsLoading} onClick={() => handleReject()}>
          Reject
        </Button>
        {type !== TAB_TYPE.COMPLETED && <Button primary disabled={modalDetailsLoading || disableApprove} onClick={() => handleApprove(selectedWorkOrder.id, closeWorkOrderUponApproval)}>
          Approve
        </Button>}
        {workOrders.length > 1 && type !== TAB_TYPE.COMPLETED ? (<Button primary disabled={disableApprove} onClick={() => handleApproveAndNext(selectedWorkOrder.id, closeWorkOrderUponApproval)}>
          Approve & Next
        </Button>) : null}
        <Button color='grey' onClick={() => onNext()}>
          Next
        </Button>
      </div>
    </div>
  )
}

export default DetailsActionsManager;