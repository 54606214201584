import React, { useState, useEffect } from 'react';
import { Icon, Label } from 'semantic-ui-react';

const showLabel = (selectedWorkOrder) => {
  if (selectedWorkOrder && selectedWorkOrder.customDataFields) {
    const instrumentRatedMeter = selectedWorkOrder.customDataFields.find(i=>i.key==='instrumentRatedMeter');
    return instrumentRatedMeter && instrumentRatedMeter.value === 'true';
  }
  return false;
}

const copyButtonStyleInitial = {
  marginLeft: '5px',
  fontSize: '10px',
  cursor: 'pointer',
  borderRadius: '5px',
  backgroundColor: '#dddddd',
  border: 'none'
}
const copyIconStyleInitial = {
  cursor: 'pointer',
  color: 'rgb(119, 119, 119)'
}

const Header = ({ currentProject, selectedWorkOrder, isQA }) => {
  const [copyButtonStyle, setCopyButtonStyle] = useState(copyButtonStyleInitial);
  const [copyIconStyle, setCopyIconStyle] = useState(copyIconStyleInitial);
  const [copyButtonText, setCopyButtonText] = useState('Copy link to clipboard');
  const [copyIconClass, setCopyIconClass] = useState('copy alternate');
  const [hasScheduler, setHasScheduler] = useState(false);
  const [hasCode, setHasCode] = useState(false);
  const schedulerDomain = process.env.NODE_ENV === 'production' ? 'peakscheduler' : 'peakworkflowdemo';

  useEffect(() => {
    if(currentProject && currentProject.projectConfiguration && currentProject.projectConfiguration.additionalFeatures && currentProject.projectConfiguration.additionalFeatures.selfScheduler) setHasScheduler(true);
    if(selectedWorkOrder && selectedWorkOrder.serviceCode) setHasCode(true);
  }, []);

  const handleClick = () => {
    if(hasScheduler) window.open(`https://${currentProject.projectShortCode}.${schedulerDomain}.com?code=${ selectedWorkOrder.serviceCode }`);
  }

  const copyToClipboard = (e) => {
    e.preventDefault();
    const url = `${window.location.origin}/dashboard/${currentProject.id}/${selectedWorkOrder.id}`;
    
    const textField = document.createElement('input');
    textField.value = url;
    document.body.appendChild(textField);
    textField.select();
    
    try {
      document.execCommand('copy');
      setCopyButtonStyle(prev => ({ ...prev, backgroundColor: '#2285d0', color: 'white' }));
      setCopyIconStyle(prev => ({ ...prev, color: 'white' }));
      setCopyButtonText('WO link copied');
      setCopyIconClass('check');
      setTimeout(() => {
        setCopyButtonStyle(copyButtonStyleInitial);
        setCopyIconStyle(copyIconStyleInitial);
        setCopyButtonText('Copy link to clipboard');
        setCopyIconClass('copy alternate');
      }, 15000);
    } catch (err) {
      console.error('Failed to copy:', err);
    } finally {
      document.body.removeChild(textField);
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex'}}>
        <Icon name='file text' />{currentProject && currentProject.projectShortCode ? currentProject.projectShortCode : ''} | Work Order Details | {(selectedWorkOrder && selectedWorkOrder.workOrderNumber) || ''}
        <button onClick={copyToClipboard} style={copyButtonStyle}>
          <i aria-hidden="true" className={`${copyIconClass} icon`} style={copyIconStyle}></i>{copyButtonText}
        </button>
        { hasScheduler &&
          <div style={{ display: 'flex', color: '#777', marginLeft: '5px', fontSize: '1em' }}>
            { hasCode ? (
              <>
                | Service code: { selectedWorkOrder.serviceCode }
                <i onClick={handleClick} aria-hidden="true" className="external alternate icon" style={{ cursor: 'pointer', color: '#777', marginLeft: '6px', fontSize: '0.8em' }}></i>
              </>
            ) : (
              '| Service code: NO CODE FOUND'
            )}
          </div>
        }
      </div>
      { isQA && showLabel(selectedWorkOrder) &&
        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Label color='red' horizontal>Instrument Rated</Label>
        </div>
      }
    </div>
  )
}

export default Header;