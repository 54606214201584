import React, { useState, useEffect, useContext } from 'react';
import { Button, Icon, Modal, Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import client from '../../../core/apollo';
import AppContext from '../../../core/context/app.context';
import WORKORDER_TYPES from '../../../constants/workOrderTypes';
import { listProjectUserByWorkOrderStatusAndCount } from '../../../core/services/user.service';
import { ASSIGN_WORKORDERS_MUTATION, UNASSIGN_WORKORDERS_MUTATION } from '../../../graphql/mutations/workorder';




export const WorkOrdersAssignmentsModal = ( props ) => {
  const {
    iconName,
    title,
    error,
    handleRefresh
  } = props
  const { currentProject, selectedWorkOrder, setModals } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [unassignedUsers, setUnassignedUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);

  useEffect(() => {
      fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const usersResponse = await listProjectUserByWorkOrderStatusAndCount(currentProject.id);
      // Filter active users with qualifications
      const users = usersResponse.filter(user => user.isActive !== false).filter(hasQualifications);
      if(users.length <= 0){
        window.alert('No users Available');
        handleClose();
      }
      setUsers(users);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const hasQualifications = (user) => {
    // leaving this in case we want to make this modal more universal;
    // original hasQual uses array of full work orders, and an array of selected work order id's;
    const workOrders = [selectedWorkOrder]
    const workOrderType = currentProject.projectWorkOrderType;
    const qualificationDefinitionKey = workOrderType === WORKORDER_TYPES.MeterDeploy || WORKORDER_TYPES.WaterMeterDeploy ? 'meterFormFactor' : 'meterSize';
    const sizes = {};
    if (user.qualificationDefinition) {
      const qualificationDefinition = JSON.parse(user.qualificationDefinition);
      for (let currentWorkOrder of workOrders) {
        const workOrder = workOrders.find(i => i.id === currentWorkOrder.id);
        const size = workOrder[qualificationDefinitionKey];
        if (qualificationDefinition[qualificationDefinitionKey].indexOf(size) === -1) {
          sizes[size] = size;
        }
      }
    }
    if (Object.keys(sizes).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  const handleWorkOrderAssignments = async (mutation, users) => {
    try {
      await client.mutate({
        mutation,
        variables: {
          projectId: currentProject.id,
          userIds: users,
          workOrderIds: [selectedWorkOrder.id]
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  const handleConfirmAssignments = async() => {
    try {
      setLoading(true);
      if (assignedUsers.length > 0) {
        await handleWorkOrderAssignments(ASSIGN_WORKORDERS_MUTATION, assignedUsers);
      }
      if (unassignedUsers.length > 0) {
        await handleWorkOrderAssignments(UNASSIGN_WORKORDERS_MUTATION, unassignedUsers);
      }
      await handleRefresh();
      setModals({ assignmentModal: false });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const addUserToAssignedList = (event, data, user) => {
    const tempAssignedUsers = [ ...assignedUsers ];
    const tempUnassignedUsers = [ ...unassignedUsers ];
    const userId = data.value;
    const assignedIndex = tempAssignedUsers.indexOf(userId);
    const unassignedIndex = tempUnassignedUsers.indexOf(userId);
    if (assignedIndex > -1) {
      tempAssignedUsers.splice(assignedIndex, 1);
    } else {
      tempAssignedUsers.push(userId);
    }
    if (unassignedIndex > -1) {
      tempUnassignedUsers.splice(unassignedIndex, 1);
    }
    setAssignedUsers(tempAssignedUsers);
    setUnassignedUsers(tempUnassignedUsers);
  }

  const addUserToUnAssignedList = (event, data) => {
    const tempAssignedUsers = [ ...assignedUsers ];
    const tempUnassignedUsers = [ ...unassignedUsers ];
    const userId = data.value;
    const unassignedIndex = tempUnassignedUsers.indexOf(userId);
    const assignedIndex = tempAssignedUsers.indexOf(userId);
    unassignedIndex > -1 ? tempUnassignedUsers.splice(unassignedIndex, 1) : tempUnassignedUsers.push(userId);
    if (assignedIndex > -1) {
      tempAssignedUsers.splice(assignedIndex, 1);
    }
    setAssignedUsers(tempAssignedUsers);
    setUnassignedUsers(tempUnassignedUsers);
  }

  const handleClose = () => {
    setModals({ assignmentModal: false });
  }

  return (
    <>
      {loading && (
        <Dimmer active={true} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999 }}>
          <Loader size='large'>Loading</Loader>
        </Dimmer>
      )}
      <Modal dimmer={{ style: { zIndex: 1002 }}} size='tiny' onClose={handleClose} open={true}>
        <Modal.Header><Icon name={iconName} /> {title || 'Assign Technician'}</Modal.Header>
        <Modal.Content>
            {error ? <p message='Error. Unable to fetch users' /> : (
            <table style={{ width: '100%' }} className='assignments-table'>
                <tbody>
                <tr>
                    <th>Assigned</th>
                    <th>Unassigned</th>
                    <th>Technician</th>
                </tr>
                {users.map((user, index) => (
                    <tr key={index}>
                    <td><Checkbox value={user.id} onChange={addUserToAssignedList} checked={assignedUsers.indexOf(user.id) > -1} /></td>
                    <td><Checkbox value={user.id} onChange={addUserToUnAssignedList} checked={unassignedUsers.indexOf(user.id) > -1} /></td>
                    <td>
                        {user.fullName} {user.counts ? `[${user.counts}]` : ''}
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            )}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={handleClose} negative>Cancel</Button>
            <Button onClick={handleConfirmAssignments} disabled={error || loading} positive icon='checkmark' labelPosition='right' content='Confirm' />
        </Modal.Actions>
      </Modal>
    </>
  )
}