import {
  SET_SHOW_INFO,
  SET_SHOW_ADDITIONAL,
  SET_SHOW_AUDIT,
  SET_SHOW_COMMENTS,
  SET_SHOW_HISTORY,
  SET_SCROLL_HEIGHT,
  RESET_WO_DETAILS_MENU_STATE,
} from '../types/workOrderDetailsMenu.types';

const initialState = {
  showInfo: true,
  showAdditional: false,
  showAudit: false,
  showComments: false,
  showHistory: false,
  scrollHeight: 0,
}

const schedulerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_INFO:
      return {
        ...state,
        showInfo: true,
        showAdditional: false,
        showAudit: false,
        showComments: false,
        showHistory: false,
      };
    case SET_SHOW_ADDITIONAL:
      return {
        ...state,
        showInfo: false,
        showAdditional: true,
        showAudit: false,
        showComments: false,
        showHistory: false,
      };
    case SET_SHOW_AUDIT:
      return {
        ...state,
        showInfo: false,
        showAdditional: false,
        showAudit: true,
        showComments: false,
        showHistory: false,
      };
    case SET_SHOW_COMMENTS:
      return {
        ...state,
        showInfo: false,
        showAdditional: false,
        showAudit: false,
        showComments: true,
        showHistory: false,
      };
    case SET_SHOW_HISTORY:
      return {
        ...state,
        showInfo: false,
        showAdditional: false,
        showAudit: false,
        showComments: false,
        showHistory: true,
      };
    case SET_SCROLL_HEIGHT:
      return { ...state, scrollHeight: action.payload };
    case RESET_WO_DETAILS_MENU_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

export default schedulerReducer;