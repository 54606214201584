import React from 'react';
import { Button, Modal, Checkbox } from 'semantic-ui-react';
import CustomErrorMessage from '../../../../shared/components/messages/error';

const handleKeyPress = (event, onModalConfirm) => {
  if (event.charCode === 13) {
    onModalConfirm(event);
  }
}

const Assignments = ({
  error,
  users,
  assignUsersList,
  unassingUsersList,
  assignUser,
  unAssignUser,
}) => {
  // Filter out inactive users
  const activeUsers = users.filter(user => user.isActive !== false);
  
  return (
    <React.Fragment>
      {error ? <CustomErrorMessage message='Error. Unable to fetch users' /> : (
        <table style={{ width: '100%' }} className='assignments-table'>
          <tbody>
            <tr>
              <th>Assigned</th>
              <th>Unassigned</th>
              <th>Technician</th>
            </tr>
            {activeUsers.map((user, index) => (
              <tr key={index}>
                <td><Checkbox value={user.id} onChange={(e, data) => assignUser(e, data, user)} checked={assignUsersList.indexOf(user.id) > -1} /></td>
                <td><Checkbox value={user.id} onChange={unAssignUser} checked={unassingUsersList.indexOf(user.id) > -1} /></td>
                <td>
                  {user.fullName} {user.Assigned ? `[${user.Assigned}]` : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </React.Fragment>
  )
}

const MapAssignmentsModal = ({
  showModal,
  closeModal,
  error,
  users,
  selectedWorkOrders,
  assignUsersList,
  unassingUsersList,
  assignUser,
  unAssignUser,
  onSubmit,
  loading
}) => (
    <div onKeyPress={(e) => handleKeyPress(e, onSubmit)}>
      <Modal dimmer='blurring' size='tiny' onClose={closeModal} open={showModal}>
        <Modal.Header>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label>Assignment</label>
            <label style={{ fontWeight: 'normal', fontSize: '18px' }}>Selected: {selectedWorkOrders.length}</label>
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <Assignments
            error={error}
            users={users}
            assignUsersList={assignUsersList}
            unassingUsersList={unassingUsersList}
            assignUser={assignUser}
            unAssignUser={unAssignUser} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} disabled={loading} negative>Cancel</Button>
          <Button
            onClick={onSubmit}
            loading={loading}
            disabled={loading || (assignUsersList.length === 0 && unassingUsersList.length === 0)}
            positive
            icon='checkmark'
            labelPosition='right'
            content='Confirm' />
        </Modal.Actions>
      </Modal>
    </div>
  )

export default MapAssignmentsModal;